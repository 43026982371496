import { default as __nuxt_component_0 } from "/opt/build/repo/components/Asset/TrophyColor.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/App/Progress/Index.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, createElementBlock as _createElementBlock, createVNode as _createVNode } from "vue";
const _hoisted_1 = { class: "grid grid-cols-6 md:grid-cols-12 text-gray-200 gap-4 text-sm px-4 items-center h-14 border-b border-gray-700" };
const _hoisted_2 = { class: "text-sm col-span-1 md:col-span-2 flex items-center justify-start" };
const _hoisted_3 = { class: "font-semibold mr-2" };
const _hoisted_4 = {
  class: "font-mono flex items-center justify-start col-span-3 md:col-span-3 overflow-hidden",
  "data-cy": "markets-last-traded-price-table-data"
};
const _hoisted_5 = {
  key: 0,
  class: "min-w-6 min-h-6 w-6 h-6 rounded-full overflow-hidden mr-2"
};
const _hoisted_6 = ["src"];
const _hoisted_7 = {
  key: 1,
  class: "min-w-6 min-h-6 w-6 h-6 rounded-full bg-white mr-2"
};
const _hoisted_8 = { class: "text-white whitespace-nowrap overflow-hidden overflow-ellipsis" };
const _hoisted_9 = { class: "block font-mono text-right col-span-2 md:col-span-2 text-sm" };
const _hoisted_10 = { class: "text-white overflow-ellipsis whitespace-nowrap" };
const _hoisted_11 = /* @__PURE__ */ _createElementVNode("span", { class: "hidden md:block col-span-1" }, null, -1);
const _hoisted_12 = { class: "hidden md:block font-mono text-right col-span-2 text-sm" };
const _hoisted_13 = { class: "hidden md:block col-span-2 text-right" };
const _hoisted_14 = ["href"];
import { getExplorerUrl } from "@injectivelabs/sdk-ui-ts";
import { BigNumberInBase } from "@injectivelabs/utils";
import { NETWORK } from "@/app/utils/constants";
export default /* @__PURE__ */ _defineComponent({
  __name: "Row",
  props: {
    rank: {
      type: Number,
      required: true
    },
    address: {
      type: String,
      required: true
    },
    percentage: {
      type: String,
      required: true
    },
    volume: {
      type: String,
      required: true
    }
  },
  setup(__props) {
    const props = __props;
    const avatarSrc = ref("");
    const explorerUrl = computed(
      () => `${getExplorerUrl(NETWORK)}/account/${props.address}/?tab=transactions`
    );
    const percentage = computed(() => Number(props.percentage));
    const volumeInBase = computed(() => new BigNumberInBase(props.volume));
    const { valueToString: volumeInBaseToFormat } = useBigNumberFormatter(
      volumeInBase,
      {
        decimalPlaces: 2
      }
    );
    onMounted(() => {
    });
    return (_ctx, _cache) => {
      const _component_AssetTrophyColor = __nuxt_component_0;
      const _component_AppProgress = __nuxt_component_1;
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(__props.rank), 1),
          __props.rank <= 3 ? (_openBlock(), _createBlock(_component_AssetTrophyColor, {
            key: 0,
            class: "min-w-4 w-4 h-4"
          })) : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _unref(avatarSrc) ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("img", { src: _unref(avatarSrc) }, null, 8, _hoisted_6)
          ])) : (_openBlock(), _createElementBlock("div", _hoisted_7)),
          _createElementVNode("span", _hoisted_8, _toDisplayString(__props.address), 1)
        ]),
        _createElementVNode("span", _hoisted_9, [
          _createElementVNode("span", _hoisted_10, _toDisplayString(_unref(volumeInBaseToFormat)) + " USD ", 1)
        ]),
        _hoisted_11,
        _createElementVNode("div", _hoisted_12, [
          _createVNode(_component_AppProgress, { value: _unref(percentage) }, null, 8, ["value"])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("a", {
            href: _unref(explorerUrl),
            target: "_blank",
            class: "text-blue-500 cursor-pointer whitespace-nowrap overflow-ellipsis"
          }, _toDisplayString(_ctx.$t("leaderboard.viewOnExplorer")), 9, _hoisted_14)
        ])
      ]);
    };
  }
});
