import { default as __nuxt_component_0 } from "/opt/build/repo/components/Common/Table/Header.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Partials/Leaderboard/Row.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/Common/EmptyList.vue";
import { default as __nuxt_component_3 } from "/opt/build/repo/components/Common/Table/Body.vue";
import { default as __nuxt_component_4 } from "/opt/build/repo/components/App/HocLoading.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "col-span-1 md:col-span-2" };
const _hoisted_2 = { class: "text-gray-200 text-xs" };
const _hoisted_3 = { class: "col-span-3 md:col-span-2" };
const _hoisted_4 = { class: "text-gray-200 text-xs" };
const _hoisted_5 = { class: "text-right col-span-2 md:col-span-3 text-gray-200 text-xs" };
const _hoisted_6 = /* @__PURE__ */ _createElementVNode("span", { class: "hidden md:block col-span-1" }, null, -1);
const _hoisted_7 = { class: "hidden md:block text-right col-span-2 text-gray-200 text-xs" };
const _hoisted_8 = /* @__PURE__ */ _createElementVNode("span", { class: "hidden md:block text-left col-span-2" }, null, -1);
const _hoisted_9 = { class: "mt-2 text-xs text-gray-500" };
export default /* @__PURE__ */ _defineComponent({
  __name: "Table",
  props: {
    status: {
      type: Object,
      required: true
    }
  },
  setup(__props) {
    const entries = computed(() => useLeaderboardStore().entries.slice(0, 6));
    return (_ctx, _cache) => {
      const _component_CommonTableHeader = __nuxt_component_0;
      const _component_PartialsLeaderboardRow = __nuxt_component_1;
      const _component_CommonEmptyList = __nuxt_component_2;
      const _component_CommonTableBody = __nuxt_component_3;
      const _component_AppHocLoading = __nuxt_component_4;
      return _openBlock(), _createElementBlock("div", null, [
        _createVNode(_component_CommonTableHeader, {
          class: "grid-cols-6 md:grid-cols-12 border-b border-gray-700",
          classes: "grid"
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_1, [
              _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t("leaderboard.rank")), 1)
            ]),
            _createElementVNode("span", _hoisted_3, [
              _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t("leaderboard.address")), 1)
            ]),
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t("leaderboard.volume")), 1),
            _hoisted_6,
            _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t("leaderboard.volumePercentage")), 1),
            _hoisted_8
          ]),
          _: 1
        }),
        _createVNode(_component_AppHocLoading, { status: __props.status }, {
          default: _withCtx(() => [
            _createVNode(_component_CommonTableBody, {
              "show-empty": _unref(entries).length === 0
            }, {
              empty: _withCtx(() => [
                _createVNode(_component_CommonEmptyList, {
                  class: "min-h-3xs bg-gray-900",
                  "data-cy": "markets-no-data-table",
                  message: _ctx.$t("leaderboard.emptyHeader")
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t("leaderboard.emptyDescription")), 1)
                  ]),
                  _: 1
                }, 8, ["message"])
              ]),
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(entries), (entry, index) => {
                  return _openBlock(), _createBlock(_component_PartialsLeaderboardRow, {
                    key: `leaderboard-row-${index}`,
                    rank: index + 1,
                    address: entry.accountID,
                    percentage: entry.perc,
                    volume: entry.volume
                  }, null, 8, ["rank", "address", "percentage", "volume"]);
                }), 128))
              ]),
              _: 1
            }, 8, ["show-empty"])
          ]),
          _: 1
        }, 8, ["status"])
      ]);
    };
  }
});
