"use strict";
import { defineComponent as _defineComponent } from "vue";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "w-full flex flex-col items-end" };
const _hoisted_2 = { class: "text-gray-200 mb-1" };
const _hoisted_3 = { class: "w-full h-2 rounded bg-gray-700 overflow-hidden" };
export default /* @__PURE__ */ _defineComponent({
  __name: "Index",
  props: {
    value: {
      type: Number,
      required: true
    }
  },
  setup(__props) {
    const props = __props;
    const style = computed(() => `transform: scaleX(${props.value / 100})`);
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(__props.value) + "%", 1),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", {
            class: "w-full h-2 bg-white origin-top-left",
            style: _normalizeStyle(_unref(style))
          }, null, 4)
        ])
      ]);
    };
  }
});
